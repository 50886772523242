/* components/Typography.module.css */

.typography {
  display: block;
}

.display {
  font-size: 6.4rem;
  line-height: 6.4rem;
}

.headingL {
  font-size: 3.6rem;
  line-height: 4rem;
}

.headingM {
  font-size: 2.6rem;
  line-height: 3.2rem;
}

.headingS {
  font-size: 2.4rem;
  line-height: 2.8rem;
}

.expressiveL {
  font-size: 2rem;
  line-height: 2.4rem;
}

.expressiveS {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

.cappedHeading {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.detail {
  font-size: 1.4rem;
  line-height: 2rem;
}

.micro {
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.body {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

/* Larger sizes in desktop */
@media (min-width: 768px) {
  .display {
    font-size: 8.8rem;
    line-height: 8.8rem;
  }

  .headingL {
    font-size: 5.4rem;
    line-height: 6rem;
  }

  .headingM {
    font-size: 3.8rem;
    line-height: 4.4rem;
  }

  .headingS {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .expressiveL {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }

  .expressiveS {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

/* FontWeight */
.weightSemiBold {
  font-weight: 600;
}

.weightMedium {
  font-weight: 500;
}

.weightRegular {
  font-weight: 400;
}

/* TextAlign */
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}
